<template>
	<v-container class="to-option" v-if="details" style="min-height: 650px">
		<v-alert dark border="top" type="error" v-if="paymentError">
			Ödeme esnasında bir hata oluştu.
			{{ paymentErrorMessage }}
			<template v-slot:prepend>
				<v-icon left>fas fa-triangle-exclamation</v-icon>
			</template>
		</v-alert>
		<div class="text-center py-5">
			<h1>Bu aracı opsiyonlamak istiyorum</h1>
			<h3 class="template-color--text">Aracı opsiyonlayın. Sizin için avantajlı fiyatları kaçırmayın.</h3>
		</div>
		<v-row>
			<v-col cols="12" md="8">
				<router-view />
			</v-col>
			<v-col cols="12" md="4">
				<car-details :car-value="car" :settings-value="carReservationSettings" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { GET_CAR_BY_ID } from '@/store/modules/listing.module';
import { mapActions, mapGetters } from 'vuex';
import CarDetails from './components/car-details/Index.vue';

export default {
	components: { CarDetails },
	name: 'ReservationIndex',
	props: {
		carValue: {
			type: Object,
			reuired: true,
		},
	},
	data() {
		return {
			car: null,
		};
	},
	computed: {
		...mapGetters({
			carReservationSettings: 'carReservationSettings',
		}),
		details() {
			return this.car;
		},
		carId() {
			return this.$route.params.id;
		},
		carImg() {
			return this.details?.photos.length > 0
				? this.details.photos[0]
				: // eslint-disable-next-line global-require
				  require('../../../../assets/images/no-photo.svg');
		},
		isReserved() {
			return this.carDetails?.isReserved;
		},
		paymentCompleted() {
			return this.$route.query.onay;
		},
		paymentError() {
			return this.$route.query['odeme-hata'];
		},
		paymentErrorMessage() {
			if (this.paymentError === 12) return '';
			if (this.paymentError === '99') return 'Lütfen daha sonra tekrar deneyin.';
			return '';
		},
	},
	mounted() {
		this.car = this.carValue;
		this.getCar();
	},
	methods: {
		...mapActions({
			getCarDetails: GET_CAR_BY_ID,
		}),
		getCar() {
			if (this.details) return;
			this.getCarDetails(this.carId).then((response) => {
				this.car = response;

				if (this.isReserved && !this.paymentError && !this.paymentCompleted) {
					this.$router.push({ name: 'carDetails', params: { id: this.carId, url: this.car.url } });
				}

				if (this.paymentError) {
					this.$cookies.remove('reserveCookie');
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.to-option {
	min-height: 100%;
	max-width: 85rem !important;
}
</style>
